
.container {
    min-height: 100vh;
    padding: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    main {
        padding: 5rem 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer {
        width: 100%;
        height: 100px;
        border-top: 1px solid #eaeaea;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            margin-left: 0.5rem;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: inherit;
        }
    }
}

%centre {
    text-align: center;
}

.title {
    @extend %centre;
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.description {
    @extend %centre;
    line-height: 1.5;
    font-size: 1.5rem;
}

.logo {
    height: 1em;
}
